thead {
    text-align: center;
    background-color: #eeeeee;
    border-color: black;
}

th {
    color: rgb(0, 0, 0);
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

td {
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

tbody tr:hover {
    background-color: #ddd;
}

.pagination {
    padding-top: 2em;
}

.pagination_inputs {
    height: 30px;
    width: 150px;
    border-radius: 6px;
}

.addTicketButton {
    font-family: 'Work Sans';
    background-color: #07b80d;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 8em;
    padding: 0.6em;
    /** 64 px. */
}

.tableButtons {
    font-family: 'Work Sans';
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 6em;
    padding: 0.5em;
    margin: auto;
}

.addTicketButton:hover,
.tableButtons:hover {
    background-color: #A2A2A1FF;
    transition: 0.7s;
}

#delete {
    background-color: #f44336;
}

#edit {
    background-color: #2196f3;
}

h4 {
    display: inline;
}

#severities {
    padding-left: 20em;
}

.goBackButton {
    font-family: 'Work Sans';
    background-color: #b87107;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 110px;
    padding: 0.8em;
    margin-left: 1em;
}

.createNewTicketButton {
    font-family: 'Work Sans';
    background-color: #0a07b8;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 175px;
    padding: 0.8em;
    margin-left: 2em;
}