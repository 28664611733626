.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.modal-main {
  position: fixed;
  background: white;
  box-shadow: 0 0 5px #ccc;
  width: 30em;
  // height: auto;
  max-height: 80%;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  border-radius: 1em;
}

.modal-button {
  padding: 0 0.8em 0;
  cursor: pointer;
  // border-left: 1px solid #eee;
  // border-bottom: 1px solid #eee;
  text-align: center;
  color: #777;
}

.modal-contents {
  padding: 1em 2em 2em;
}

.modal-header {
  border-bottom: 1px solid #ddd;

  h3 {
    margin: 0.25em 0 0;
    text-align: center;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}